<template>
  <div class="login">
    <div class="title">管理员登录</div>
    <div class="login_fields">
      <div class="login_fields_inp">
        <input
          id="username"
          name="username"
          placeholder="用户名"
          v-model="loginInfo.username"
          type="text"
        />
      </div>
      <div class="login_fields_inp">
        <input
          type="password"
          id="password"
          name="password"
          placeholder="密码"
          v-model="loginInfo.password"
        />
      </div>
      <div class="login_fields_inp login_captcha">
        <input
          class="login_fields_inp_code"
          id="captcha"
          name="captcha"
          v-model="captcha"
          placeholder="验证码"
          maxlength="4"
          type="text"
          autocomplete="off"
          @keyup.enter="loginPost"
        />
        <div @click="refreshCodeClick">
          <s-identify
            style="position: relative"
            :identifyCode="identifyCode"
            @click="refreshCode()"
          ></s-identify>
        </div>
      </div>
      <div class="select_list">
        <label class="select_list_title">
          <input
            type="checkbox"
            name="rememberMe"
            id="rememberMe"
            value=""
            v-model="loginInfo.rememberMe"
            @click="rememberMeClick"
          />
          使我保持登录状态</label
        >
      </div>
      <div class="login_fields__submit">
        <div class="login-but" @click="loginPost">登 录</div>
        <div class="wx_login_but" style="display: none">
          <svg
            t="1605163721344"
            class="icon"
            viewBox="0 0 1264 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1686"
            width="32"
            height="32"
          >
            <path
              d="M856.493827 309.728395c15.802469 0 28.444444 0 44.246914 3.160494C859.654321 132.740741 666.864198 0 448.790123 0 202.271605 0 0 167.506173 0 379.259259c0 123.259259 66.37037 221.234568 180.148148 300.246914l-44.246913 132.740741 154.864197-79.012346c56.888889 9.481481 101.135802 22.123457 158.024691 22.123457 12.641975 0 28.444444 0 41.08642-3.160494-9.481481-31.604938-12.641975-63.209877-12.641975-94.814815-3.160494-189.62963 167.506173-347.654321 379.259259-347.654321zM616.296296 189.62963c34.765432 0 56.888889 22.123457 56.888889 56.888889s-22.123457 56.888889-56.888889 56.888888-66.37037-22.123457-66.37037-56.888888c-3.160494-34.765432 31.604938-56.888889 66.37037-56.888889zM303.407407 300.246914c-34.765432 0-66.37037-22.123457-66.37037-56.888889-3.160494-31.604938 31.604938-53.728395 66.37037-53.728395s56.888889 22.123457 56.888889 56.888889c-3.160494 31.604938-25.283951 53.728395-56.888889 53.728395zM1264.197531 657.382716c0-176.987654-180.148148-322.37037-379.259259-322.37037-211.753086 0-379.259259 145.382716-379.25926 322.37037S673.185185 979.753086 884.938272 979.753086c44.246914 0 88.493827-12.641975 132.74074-22.123456l123.25926 66.37037-34.765432-110.617284c91.654321-66.37037 158.024691-154.864198 158.024691-256zM761.679012 600.493827c-22.123457 0-44.246914-22.123457-44.246913-44.246913s22.123457-44.246914 44.246913-44.246914c34.765432 0 56.888889 22.123457 56.888889 44.246914-3.160494 22.123457-25.283951 44.246914-56.888889 44.246913z m246.518519 0c-22.123457 0-44.246914-22.123457-44.246914-44.246913s22.123457-44.246914 44.246914-44.246914c34.765432 0 56.888889 22.123457 56.888889 44.246914-3.160494 22.123457-25.283951 44.246914-56.888889 44.246913z m0 0"
              fill="#30CB5C"
              p-id="1687"
            ></path>
          </svg>
        </div>
      </div>
      <div class="buttom-text">
        Copyright 河南农工厂生态科技有限公司 水云仓 v6.0<br /><br />
        <a class="pull-left" id="record_no" href="https://beian.miit.gov.cn"
          >备案号：豫ICP备20011605号-2</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import sIdentify from "./c-sIdentify/sIdentify.vue";
export default {
  components: {
    sIdentify,
  },
  data() {
    const letters = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    return {
      identifyCode: "",
      identifyCodes: [...letters], //根据实际需求加入自己想要的字符

      captcha: "",

      loginInfo: {
        username: null,
        password: null,
        rememberMe: true,
      },
      token: "",
    };
  },
  mounted() {
    this.refreshCode();
  },
  computed: {},
  methods: {
    get() {},
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      // console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      // console.log("data, len:", data, len);
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    refreshCodeClick() {
      this.refreshCode();
    },
    // 键盘回车事件
    handleKeyPress() {
      console.log("回车键被按下");
    },
    loginPost() {
      this.$http
        .post(
          "/user_Logon/logon",
          qs.stringify({
            AccountNumber: this.loginInfo.username,
            password: this.loginInfo.password,
          })
        )
        .then((res) => {
          console.log(res);
          if (!res.data.token) {
            this.$message.error(res.data.msg);
            this.loginInfo = {};
            this.captcha = "";
            this.refreshCode();
          } else {
            if (this.captcha.toUpperCase() == this.identifyCode) {
              if (this.loginInfo.rememberMe) {
                console.log(res);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("commercial_id", res.data.commercial_id);
                localStorage.setItem("user_id", res.data.user_id);
                localStorage.setItem("operator", this.loginInfo.username);
              } else {
                sessionStorage.setItem("token", res.data.token);
                sessionStorage.setItem("commercial_id", res.data.commercial_id);
                sessionStorage.setItem("user_id", res.data.user_id);
              }
              this.$message({
                message: "登录成功",
                type: "success",
              });
              setTimeout(() => {
                this.$emit("loginT", true);
              }, 0);
            } else {
              this.$message.error("验证码错误");
              setTimeout(() => {
                this.captcha = "";
                this.refreshCode();
              }, 1000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rememberMeClick() {
      this.loginInfo.rememberMe = !this.loginInfo.rememberMe;
    },
  },
};
</script>
<style scoped lang="less">
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  max-width: 500px;
  min-width: 300px;
  box-shadow: rgb(6 17 47 / 70%) -15px 15px 15px;
  background-image: linear-gradient(
    230deg,
    rgba(53, 57, 74, 0) 0%,
    rgb(0, 0, 0) 100%
  );
  transition: all 0.3s;
  opacity: 0.8;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 20px;
  margin-top: 20px;
}

.title {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.login_fields {
  // height: 208px;
  padding: 20px;

  .login_fields_inp {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    width: 90%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    input {
      flex: 1;
      background: none;
      border: 0;
      outline: none;
      color: #fff;
      font-size: 1.2em;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: #ddd !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    .login_fields_inp_code {
      width: 150px;
    }
  }

  .login_captcha {
    position: relative !important;
  }
  .select_list {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 90%;
    margin: 0 auto 10px;

    .select_list_title {
      color: rgba(255, 255, 255, 0.8);
      padding-left: 10px;
      font-size: 14px;
      /* width: 60px; */
    }
  }

  .login_fields__submit {
    display: flex;
    margin-top: 15px;

    .login-but {
      margin: auto;
      width: 80%;
      line-height: 50px;
      border-radius: 25px;
      font-size: 18px;
      text-align: center;
      color: #fff;
      background-image: linear-gradient(
        30deg,
        rgb(161, 200, 229),
        rgb(150, 182, 238),
        rgb(101, 157, 198)
      );
      /* box-shadow: 5px 5px 10px 2px #ddd; */
      cursor: pointer;
      user-select: none;
    }
  }
  .buttom-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 20px;
  }
}
</style>
